import qs from "qs";
import { tryFetch } from "@shared/utils/Network";

const host = TSS_URL;
const entityCode = 43;

export interface ProductSpecificationsMeasurement {
    value: number;
    unit: "twip" | "mm" | "cm";
}

export interface ProductSpecificationDimensions {
    height: ProductSpecificationsMeasurement;
    width: ProductSpecificationsMeasurement;
}

export interface ProductSpecificationsResponse {
    data: {
        productKey: string;
        targetSpecifications: {
            projectionId: string;
            designProjectionId?: string;
            version?: string;
            key?: string;
            ducIds?: string[];
            panels: {
                id?: string;
                name?: string;
                dpsId?: string;
                fullBleed: {
                    shapeType: string;
                    dimensions: ProductSpecificationDimensions;
                };
                safeArea: {
                    x: ProductSpecificationsMeasurement;
                    y: ProductSpecificationsMeasurement;
                    shapeType?: string;
                    dimensions: ProductSpecificationDimensions;
                };
                finish?: string;
                premiumFinishes?: string[];
                decorationTechnology?: string;
                panelType?: string;
                isBlank: boolean;
                dpsIds?: string[];
            }[];
            isFolded: boolean;
            substrate?: boolean;
        };
    };
}

/**
 * Get a product's specifications based on locale, product key, and product selections
 * @param {string} locale Locale
 * @param {string} productKey Product Key
 * @param {number} productVersion Product Version
 * @param {Object} selections Product Selections
 * @returns Target Product Specifications
 */
export async function getProductSpecifications(
    locale: string,
    productKey: string,
    productVersion: number,
    selections: Record<string, string>
): Promise<ProductSpecificationsResponse> {
    const tssQueryString = qs.stringify({
        optionSelections: selections,
        culture: locale
    });
    const tssUrl = `${host}/api/v5/productSpecifications/${productKey}/version/${productVersion}?${tssQueryString}`;
    return tryFetch({
        url: tssUrl,
        options: {
            method: "GET",
            headers: {
                From: "studio",
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        },
        moduleFunction: "tss:getProductSpecifications",
        friendlyDescription: "get product specifications from product key",
        entityCode
    });
}
