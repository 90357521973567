import React, { ReactNode, useCallback, useContext, useMemo, useState } from "react";
import { getProductsFromCalcifer } from "@shared/utils/Calcifer";
import { useAppSelector } from "@shared/redux";

export type CopyDesignContextType = {
    init: () => void;
    setSearchText: React.Dispatch<React.SetStateAction<string>>;
    products: Product[];
    isLoading: boolean;
    error: string | null;
    searchText: string;
    product: Product | null;
    setProduct: React.Dispatch<React.SetStateAction<Product | null>>;
};
export const CopyDesignContext = React.createContext<CopyDesignContextType | undefined>(undefined);

export type Product = {
    name: string;
    coreProductId: string;
};

export const useCopyDesignContext = () => {
    const result = useContext(CopyDesignContext);
    if (!result) {
        throw new Error("useCopyDesignContext is being used outside of CopyDesignProvider");
    }
    return result;
};

export const CopyDesignProvider = ({ children }: { children: ReactNode | ReactNode[] }) => {
    const [products, setProducts] = useState<Product[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [searchText, setSearchText] = useState<string>("");
    const locale = useAppSelector(state => state.locale);
    const stopLoading = () => setIsLoading(false);
    const [product, setProduct] = useState<Product | null>(null);

    const init = useCallback(() => {
        if (products.length > 0 || isLoading) return;
        getProductsFromCalcifer(locale).then(setProducts).catch(setError).finally(stopLoading);
    }, [products, isLoading, locale]);

    const value = useMemo(
        () => ({
            init,
            setSearchText,
            products,
            isLoading,
            error,
            searchText,
            product,
            setProduct
        }),
        [products, isLoading, error, searchText, init, product, setProduct]
    );
    return <CopyDesignContext.Provider value={value}>{children}</CopyDesignContext.Provider>;
};

CopyDesignProvider.displayName = "CopyDesignProvider";
