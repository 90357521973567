import React, { useState } from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { Button } from "@vp/swan";
import { CopyDesignDialog } from "./CopyDesignDialog";
import { CopyDesignProvider } from "./CopyDesignContext";

const messages = defineMessages({
    copydesign: {
        id: "studio.components.copydesign.button.label",
        defaultMessage: "Copy Design",
        description: {
            note: "Label for button that opens the Copy Design dialog"
        }
    }
});

export const CopyDesignButton = () => {
    const { t } = useTranslationSSR();
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const onClick = () => {
        setIsDialogOpen(true);
    };

    const onClose = () => {
        setIsDialogOpen(false);
    };

    return (
        <Button skin={"primary"} width={"standard"} size={"standard"} onClick={onClick} className="swan-pl-6">
            {t(messages.copydesign.id)}
            <CopyDesignProvider>
                <CopyDesignDialog isOpen={isDialogOpen} onClose={onClose} />
            </CopyDesignProvider>
        </Button>
    );
};

CopyDesignButton.displayName = "CopyDesignButton";
