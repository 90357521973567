import type { DSS } from "@vp/types-ddif";
import { constructWorkEntity, postWorkEntity } from "@shared/utils/Work";
import { saveDocumentToUds } from "../DocumentStorage";

/**
 * Take a document, saves it to UDS and constructs a work entity based on the current state provided
 * @param {object} document cimDoc
 * @param {string} authToken authToken
 * @param {object} newProductCurrentState defines new product's current state
 * @returns new work entity
 */
export async function generateAndSaveNewWorkEntity(
    document: DSS.DesignDocument,
    authToken: string,
    udsTenant: string,
    newProductCurrentState: any
) {
    const udsResponse = await saveDocumentToUds({ document, authToken, udsTenant });

    const workEntity = await constructWorkEntity(authToken, udsResponse, newProductCurrentState, "");
    return postWorkEntity({ workEntity, authToken });
}
