import React, { useEffect } from "react";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import {
    Typography,
    FlexBox,
    Divider,
    LegacyModalDialog,
    LegacyModalDialogHeader,
    LegacyModalDialogBody,
    LegacyModalDialogTitle,
    LegacyModalDialogContent,
    LegacyModalDialogCloseButton,
    BoundedContent
} from "@vp/swan";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { useAppSelector } from "@shared/redux";
import { useCopyDesignContext } from "./CopyDesignContext";
import { ProductSearch } from "./ProductSearch";
import { ProductOptionsSelector } from "./ProductOptionsSelector";
import "./copydesign.scss";

const messages = defineMessages({
    dialogHeader: {
        id: "easel.copy-design.dialogHeader",
        defaultMessage: "Copy Design",
        description: {
            note: "Header for the copy design dialog"
        }
    },
    ariaCloseButton: {
        id: "easel.copy-design.dialog.ariaCloseButton",
        defaultMessage: "Close dialog",
        description: {
            note: "x Close button accessability text / hover text"
        }
    },
    instructions: {
        id: "easel.copy-design.dialog.instructions",
        defaultMessage:
            "Search for a product to copy to. Select the options for the product. Copy your current design onto the new product. Copy will open in a new tab.",
        description: {
            note: "Instructions for the copy design dialog."
        }
    },
    caution: {
        id: "easel.copy-design.dialog.caution",
        defaultMessage:
            "⚠ Copying from one print technology to another (IE print to engraved, etc.) may copy, but result in unusable documents.",
        description: {
            note: "Caution warnings for the using the copy design dialog."
        }
    },
    loading: {
        id: "easel.copy-design.dialog.loading",
        defaultMessage: "Loading...",
        description: {
            note: "Loading message when the product list is being fetched"
        }
    }
});

type Props = {
    isOpen: boolean;
    onClose: () => void;
};

export function CopyDesignDialog(props: Props) {
    const { isOpen, onClose } = props;
    const { t } = useTranslationSSR();
    const { isSmall } = useStudioLayout();
    const { isLoading, init, product, setProduct } = useCopyDesignContext();
    const prd = product?.coreProductId;
    const locale = useAppSelector(state => state.locale);

    const onCancel = () => {
        setProduct(null);
        onClose();
    };

    useEffect(() => {
        init();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <LegacyModalDialog
            className="copy-design-modal"
            isOpen={isOpen}
            onRequestDismiss={onCancel}
            paddingTop={isSmall ? undefined : 4}
            takeOver={true}
            data-dialog-enable-browser-history="xs-only"
            onClick={(event: React.MouseEvent) => {
                event.preventDefault();
            }}
            data-dcl-prevent-canvas-items-deselection
        >
            <LegacyModalDialogContent
                aria-label={t(messages.dialogHeader.id)}
                fullBleed={true}
                className="easel-copydesign-dialog-content"
            >
                <LegacyModalDialogHeader className="easel-copydesign-dialog-header" mb={2} ml={4}>
                    <BoundedContent>
                        <LegacyModalDialogCloseButton visuallyHiddenLabel={t(messages.ariaCloseButton.id)} />
                        <LegacyModalDialogTitle>{t(messages.dialogHeader.id)}</LegacyModalDialogTitle>
                        <Typography fontSize="1" mt={2}>
                            {t(messages.instructions.id)}
                        </Typography>
                        <Typography fontSize="1" mt={2}>
                            {t(messages.caution.id)}
                        </Typography>
                    </BoundedContent>
                </LegacyModalDialogHeader>
                <LegacyModalDialogBody className="easel-copydesign-modal-content">
                    <BoundedContent>
                        <FlexBox flexDirection="column" padding={4}>
                            {isLoading ? (
                                <p>{t(messages.loading.id)}</p>
                            ) : (
                                <FlexBox flexDirection="column">
                                    <ProductSearch />
                                    <Divider mb={2} />
                                    {prd && (
                                        <FlexBox flexDirection="column">
                                            <ProductOptionsSelector prd={prd} locale={locale} />
                                        </FlexBox>
                                    )}
                                </FlexBox>
                            )}
                        </FlexBox>
                    </BoundedContent>
                </LegacyModalDialogBody>
            </LegacyModalDialogContent>
        </LegacyModalDialog>
    );
}
CopyDesignDialog.displayName = "CopyDesignDialog";
