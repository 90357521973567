import React from "react";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import {
    LegacyComboboxOption,
    LegacyComboboxInput,
    LegacyComboboxList,
    LegacyComboboxPopover,
    LegacyCombobox
} from "@vp/swan";
import { useCopyDesignContext, Product } from "./CopyDesignContext";

const messages = defineMessages({
    loading: {
        id: "easel.copy-design.dialog.loading",
        defaultMessage: "Loading..",
        description: {
            note: "Loading message when the product list is being fetched"
        }
    },
    failedLoading: {
        id: "easel.copy-design.dialog.failedLoading",
        defaultMessage: "Failed to fetch product list",
        description: {
            note: "Error message when the product list failed to load"
        }
    },
    search: {
        id: "easel.copy-design.dialog.search",
        defaultMessage: "Search by PRD or Name",
        description: {
            note: "Search box placeholder text"
        }
    },
    nolist: {
        id: "easel.copy-design.dialog.nolist",
        defaultMessage: "Either product list not loaded, or no products found for this locale",
        description: {
            note: "No products found"
        }
    }
});

const makeLabel = (product: Product) => `${product.coreProductId}: ${product.name}`;

export function ProductSearch() {
    const { t } = useTranslationSSR();
    const { products, error, setSearchText, searchText, setProduct } = useCopyDesignContext();
    const filteredProducts = products
        .filter(product => makeLabel(product).toLowerCase().includes(searchText.toLowerCase()))
        .slice(0, 10);

    const onSelect = (item: string) => {
        if (!item) {
            setProduct(null);
            return;
        }
        const [PRD, name] = item.split(/:(.+)/);
        setProduct({ name, coreProductId: PRD });
    };

    return (
        <>
            {error && <p className="error-text">{t(messages.failedLoading.id)}</p>}
            {products.length > 0 ? (
                <LegacyCombobox onSelect={onSelect} className="search-combobox">
                    <LegacyComboboxInput
                        onChange={(e: any) => setSearchText(e.target.value)}
                        placeholder={t(messages.search.id)}
                    />
                    <LegacyComboboxPopover className="combobox-popover">
                        <LegacyComboboxList>
                            {filteredProducts.map(product => (
                                <LegacyComboboxOption key={product.coreProductId} value={makeLabel(product)} />
                            ))}
                        </LegacyComboboxList>
                    </LegacyComboboxPopover>
                </LegacyCombobox>
            ) : (
                <p>{t(messages.nolist.id)}</p>
            )}
        </>
    );
}
ProductSearch.displayName = "ProductSearch";
