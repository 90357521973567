import React, { useEffect, useState } from "react";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { SimpleConfigurator } from "@vp/simple-configurator";
import { Typography, Box, Divider, AlertBox } from "@vp/swan";
import { useDesigner } from "@designer-suite";
import { getDesignDocumentFromDesigner } from "@utilities";
import { replaceQueryParam } from "@shared/utils/WebBrowser";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { newRelicWrapper } from "@shared/utils/Errors";
import { getProductDefaultsFromCalcifer } from "@shared/utils/Calcifer";
import { generateAndSaveNewWorkEntity } from "@shared/utils/Save";
import {
    getTransformedDocumentForCopyDesign,
    cleanseCimDocForTransformation,
    getProductSpecifications
} from "@shared/utils/ProductTransformation";
import { displacePanel, translateTSSPanel } from "./copyDesignUtils";
import { CopyToProductButton } from "./CopyToProductButton";

const messages = defineMessages({
    defaultSelectionOptionsError: {
        id: "easel.copy-design.product.options.defaultSelectionOptionsError",
        defaultMessage: "Could not get default selections for product's options",
        description: {
            note: "Error message for when selections could not be fetched successfully"
        }
    },
    copyError: {
        id: "easel.copy-design.product.options.copyError",
        defaultMessage: "Failed to copy",
        description: {
            note: "Error message for when the copy fails"
        }
    },
    copyNotAvailableWarning: {
        id: "easel.copy-design.product.options.copyNotAvailableForSelectedProduct",
        defaultMessage: "Not available to copy selected product",
        description: {
            note: "Warning message when copying to a selected product is not available"
        }
    }
});

interface ProductInfo {
    version: number;
    quantity: number;
    options: any;
}

interface Props {
    includeVat?: boolean;
    locale: string;
    prd: string;
}

export function ProductOptionsSelector(props: Props) {
    const { prd, includeVat = false, locale } = props;
    const designer = useDesigner();
    const { auth } = useIdentityContext();
    const authToken = auth.getToken();
    const { t } = useTranslationSSR();
    const [optionsError, setOptionsError] = useState<string | null>(null);
    const [copyError, setCopyError] = useState<string | null>(null);
    const [productInfo, setProductInfo] = useState<ProductInfo | null>(null);
    const [copyErrorInfo, setCopyErrorInfo] = useState<any | null>(null);
    const [showErrorInfo, setShowErrorInfo] = useState<boolean>(false);
    const [copyWarning, setCopyWarning] = useState<string | null>(null);

    useEffect(() => {
        setProductInfo(null);
        setOptionsError(null);
        setCopyError(null);
        setCopyErrorInfo(null);
        setCopyWarning(null);
    }, [prd]);

    useEffect(() => {
        if (!prd) {
            return;
        }
        async function getInfo() {
            try {
                const {
                    productVersion,
                    selectedOptions: optionsResult,
                    quantity: quantityResults
                } = await getProductDefaultsFromCalcifer(prd, locale);
                // Tweak to include a backside by default
                if (optionsResult?.Backside === "Blank") {
                    optionsResult.Backside = "Color";
                }
                if (optionsResult?.PrintColor === "4/0 - CMYK") {
                    optionsResult.PrintColor = "4/4 - CMYK/CMYK";
                }
                setProductInfo({ version: productVersion, quantity: quantityResults, options: optionsResult });
            } catch (e) {
                setOptionsError(t(messages.defaultSelectionOptionsError.id));
            }
        }
        getInfo();
    }, [prd, locale, t]);

    if (!productInfo || !locale || !prd || !designer || !window) {
        return null;
    }

    return (
        <>
            {optionsError && (
                <AlertBox skin="error">
                    <Typography>{optionsError}</Typography>
                </AlertBox>
            )}
            {copyWarning && (
                <AlertBox skin="warning">
                    <Typography>{copyWarning}</Typography>
                </AlertBox>
            )}
            {copyError && (
                <AlertBox skin="error" onClick={() => setShowErrorInfo(!showErrorInfo)}>
                    <Typography>{copyError}</Typography>
                </AlertBox>
            )}
            {copyErrorInfo && showErrorInfo && (
                <>
                    <Divider mt={2} mb={2} />
                    <AlertBox skin="error">
                        <Typography>{copyErrorInfo?.message}</Typography>
                    </AlertBox>
                    <Box marginX={"auto"} pt={4}>
                        {copyErrorInfo?.stack &&
                            copyErrorInfo?.stack?.split("\n")?.map((line: string, index: number) => {
                                return (
                                    <Typography key={index} fontWeight="bold" pl={index > 0 ? 4 : 0}>
                                        {line}
                                    </Typography>
                                );
                            })}
                    </Box>
                </>
            )}
            {(copyError || optionsError) && <Divider mt={2} mb={2} />}
            {!optionsError && (
                <SimpleConfigurator
                    culture={locale}
                    productKey={prd}
                    productVersion={productInfo?.version}
                    includeVat={includeVat}
                    // Some products need pre-picked options, others fail...
                    initialSelections={productInfo?.options}
                    initialQuantity={productInfo?.quantity}
                    merchandisingExperience="allMerchandisedOptions"
                    tenant="vistaprint"
                    onSaveSelections={async (selections, quantity, _document, saveContext) => {
                        try {
                            const designDocument = getDesignDocumentFromDesigner(false);
                            const {
                                document: { panels }
                            } = designDocument;

                            cleanseCimDocForTransformation(designDocument);

                            const targetSpecifications = await getProductSpecifications(
                                locale,
                                prd,
                                productInfo?.version,
                                selections
                            );

                            // Scales current document items
                            designDocument.document.panels = targetSpecifications.data.targetSpecifications.panels.map(
                                (targetPanel: any) => {
                                    // Do a panel name check of tss panels to original panels
                                    const sourcePanel = panels.find((panel: any) => targetPanel.name === panel.name);
                                    if (sourcePanel) {
                                        const {
                                            height: { value: height },
                                            width: { value: width }
                                        } = targetPanel.fullBleed.dimensions;
                                        return displacePanel(
                                            designer,
                                            sourcePanel,
                                            { width, height },
                                            "Source Matcher"
                                        );
                                    }
                                    // Create a blank Panel if it doesnt match
                                    return translateTSSPanel(targetPanel);
                                }
                            );
                            const { targetDocument } = await getTransformedDocumentForCopyDesign({
                                designDocument,
                                locale,
                                matcher: "Source Matcher",
                                targetSpecifications,
                                authToken
                            });

                            if (!targetDocument) {
                                // if no targetDocument is returned, we may not have been able to find an available source match
                                // this is likely because the decoration technologies did not match
                                // show a warning message for care agents
                                setCopyWarning(t(messages.copyNotAvailableWarning.id));
                                return;
                            }

                            // by default the owner is transformation which isn't right
                            delete targetDocument.owner;

                            // Generates new work entity from new document
                            const savedWorkEntity = await generateAndSaveNewWorkEntity(
                                targetDocument,
                                authToken,
                                UDS_TENANT,
                                {
                                    productKey: prd,
                                    mpvId: saveContext.mpvId,
                                    productName: saveContext.productName,
                                    locale,
                                    quantity: quantity || saveContext.price?.quantity || "100",
                                    productVersion: productInfo?.version,
                                    customerSelectedProductOptions: selections
                                }
                            );

                            // Creates and opens a new studio URL with new workId in a new tab
                            const newStudioUrl = replaceQueryParam(
                                window.location.href,
                                `workId=${savedWorkEntity.workId}`
                            );
                            window.open(newStudioUrl, "_blank");
                            setCopyError(null);
                            setCopyErrorInfo(null);
                        } catch (e) {
                            setCopyError(t(messages.copyError.id));
                            setCopyErrorInfo(e);
                            newRelicWrapper.noticeError(e, {
                                productKey: prd,
                                locale,
                                productVersion: productInfo?.version,
                                customerSelectedProductOptions: selections
                            });
                        }
                    }}
                    onCancel={() => {}}
                    renderingOverrides={{ responseButtons: CopyToProductButton }}
                />
            )}
        </>
    );
}
ProductOptionsSelector.displayName = "ProductOptionsSelector";
