import React from "react";
import { Button } from "@vp/swan";
import { ResponseButtonsProps } from "@vp/simple-configurator/dist/types/RenderingOverrides";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";

const messages = defineMessages({
    copyButton: {
        id: "easel.copy-design.dialog.copyButton",
        defaultMessage: "Copy",
        description: {
            note: "Copy button for the Copy Design dialog"
        }
    }
});

export function CopyToProductButton(props: ResponseButtonsProps) {
    const { onSaveSelections } = props;
    const { t } = useTranslationSSR();

    return (
        <>
            <Button width="full-width" skin="primary" size="mini" mb={3} onClick={onSaveSelections}>
                {t(messages.copyButton.id)}
            </Button>
        </>
    );
}
CopyToProductButton.displayName = "CopyToProductButton";
