import { v4 as uuidv4 } from "uuid";
import cloneDeep from "lodash/cloneDeep";
import { mapTextContent, scaledDisplace } from "@designer-suite";
import type { Designer } from "src/easel/designer-suite/@types/designer";

/**
 * Converts cimDoc Item to a Backbone item model
 * @param item cimDoc item
 * @param itemType Type of item being converted
 * @returns Backbone item model
 */
export const convertCimDocToBackboneItemModel = (item: any, itemType: string): ModelAttributes => {
    return {
        height: parseFloat(item.position.height),
        width: parseFloat(item.position.width),
        top: parseFloat(item.position.y),
        left: parseFloat(item.position.x),
        content: item.content,
        module: item.type || itemType,
        type: item.type,
        data: cloneDeep(item.data),
        analysis: {},
        rotation: 0,
        overprints: [],
        requestId: "",
        previewUrl: ""
    };
};

/**
 * Applies scaled backbone item model to cimDoc items
 * @param item cimDoc Item
 * @param convertedItem backbone item model
 * @returns scaled cimDoc Item
 */
export const convertBackboneToCimDocItemModel = (item: any, convertedItem: ModelAttributes) => {
    const { left, top, width, height, content, data } = convertedItem;
    return {
        ...item,
        content,
        data,
        position: { x: `${left}mm`, y: `${top}mm`, width: `${width}mm`, height: `${height}mm` }
    };
};

/**
 * Scale cim doc items based on target dimensions
 * @param designer
 * @param newDimensions dimensions of target panel
 * @param originalDimensions dimensions of source panel
 * @param items array of cimDoc items
 * @param itemType type of item to be scaled
 * @returns scaled cimDoc items
 */
export const displaceItems = (
    designer: Designer,
    newDimensions: Dimensions,
    originalDimensions: Dimensions,
    items: any[],
    itemType: string
) => {
    if (!items) {
        return null;
    }
    const convertedItems = items.map(item => convertCimDocToBackboneItemModel(item, itemType));
    scaledDisplace(designer, newDimensions, originalDimensions, convertedItems);
    return items.map((item: any, index: number) => {
        return convertBackboneToCimDocItemModel(item, convertedItems[index]);
    });
};

/**
 * Will scale all items on a source panel based on target panel
 * @param designer
 * @param panel Original panel
 * @param targetDimensions Dimensions of target panel
 * @returns Orignal panel with scaled items
 */
export const displacePanel = (designer: Designer, panel: any, targetDimensions: Dimensions, matcher: string) => {
    const sourceDimensions = {
        height: parseInt(panel.height, 10),
        width: parseInt(panel.width, 10)
    };
    const { textAreas, images, itemReferences, shapes } = panel;

    const displacedTextAreas = displaceItems(designer, targetDimensions, sourceDimensions, textAreas, "TextField");

    // Returned fontSize is a number and needs to be converted to pt
    displacedTextAreas?.forEach((textArea: any) => {
        // eslint-disable-next-line no-param-reassign
        textArea.content = mapTextContent(textArea.content, ({ fontSize, ...fieldProperties }) => {
            return {
                ...fieldProperties,
                fontSize: typeof fontSize === "number" ? `${fontSize}pt` : fontSize
            };
        });
    });

    if (matcher && matcher === "Template Matcher") {
        return panel;
    }

    return {
        ...panel,
        textAreas: displacedTextAreas,
        images: displaceItems(designer, targetDimensions, sourceDimensions, images, "UploadedImage"),
        // Classifying all reference items as ItemReference, but function knows to differentiate based on its item type.
        itemReferences: displaceItems(designer, targetDimensions, sourceDimensions, itemReferences, "ItemReference"),
        // Classifying all shapes as curves, but function knows to differentiate based on its item type.
        shapes: displaceItems(designer, targetDimensions, sourceDimensions, shapes, "Curve")
    };
};

/**
 * Translates TSS panels to a blank cimDoc panels
 * @param targetPanel TSS panel
 * @returns empty cimDoc Panel
 */
export const translateTSSPanel = (targetPanel: any) => {
    return {
        id: uuidv4(),
        name: targetPanel.name,
        width: `${targetPanel.fullBleed.dimensions.width.value}mm`,
        height: `${targetPanel.fullBleed.dimensions.height.value}mm`,
        decorationTechnology: targetPanel.decorationTechnology,
        colorMode: "blank"
    };
};
